import { convertFirebaseDateToLocal } from "@/firebase/utils";
import { Profile } from "@/store/auth/state";
import {
  ChatTypes,
  MessageType,
  GroupRawAudioMessage,
  AudioMessage,
} from "@/store/chats/types";
import { User } from "@/store/users/state";
import { getGroupMessageReadBy } from "../chat/getGroupMessageReadBy";
import { getReadGroupMessage } from "../chat/getReadGroupMessage";
import { getSenderName } from "../chat/getSenderName";
import { findUserById } from "../users";

interface ConvertRawGroupAudioMessageArgs {
  rawMessage: GroupRawAudioMessage;
  memberIds: number[];
  auth: Profile;
  users?: User[];
}

export const convertRawGroupAudioMessage = ({
  rawMessage,
  memberIds,
  auth,
  users,
}: ConvertRawGroupAudioMessageArgs): AudioMessage => {
  const members: User[] = memberIds
    .map((memberId) => {
      const user = findUserById(memberId, users);
      return user;
    })
    .filter(Boolean) as User[];
  const { senderId, attachments, hide, sentAt, id } = rawMessage;
  const hidden = Boolean(hide) && !attachments?.length;

  return {
    chatType: ChatTypes.ONE_TO_ONE_CHAT,
    id,
    senderId,
    senderName: getSenderName(senderId, auth.id, members),
    isMine: auth.id === senderId,
    sentAt: convertFirebaseDateToLocal(sentAt),
    hidden,
    readBy: getGroupMessageReadBy(rawMessage),
    read: getReadGroupMessage(rawMessage, auth),
    attachments,
    type: MessageType.Audio,
  };
};
