import { CancelTokenSource } from "axios";
import { User } from "../users/state";
import { VideoDisplayStatus, IdPrefixedResultObject } from "@/types/chat";
import firebase from "@/firebase/firebase";
import { CallType } from "@/types/webview";
import { IconType } from "@/types/icons";

export enum AttachmentTypes {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  OTHERS = "OTHERS",
}

export enum LocationTypes {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface Attachment {
  id?: string;
  size: number;
  name: string;
  uploaded: boolean;
  uploadProgress?: number;
  cancelToken?: CancelTokenSource;
  type: AttachmentTypes;
  location?: LocationTypes;
  url?: string;
  path?: string;
  filename: string;
  contentType?: string;
  extname?: string;
  file: File;
  compressedFile?: File;
  dimensions?: Dimensions;
  width?: number;
  height?: number;
  cdnId?: string;
  displayStatus: VideoDisplayStatus;
  isUploading?: boolean;
  thumbnailUrl?: string;
  duration?: string;
}

export interface CompressedFile {
  file: File;
  type: AttachmentTypes;
}

export interface ReadBy {
  userId?: number;
  readAt?: number;
  read: boolean;
}

export interface OneToOneRawChat {
  id: string;
  createdAt: firebase.firestore.Timestamp;
  hide?: boolean;
  lastMessage?: RawMessage;
  activeCall: ActiveCall | null;
  pendingCallType?: CallType;
  type: FirestoreChatType.OneToOne;
}

export interface OneToOneRawTextMessage {
  id: string;
  read?: boolean;
  readAt?: firebase.firestore.Timestamp;
  senderId: number;
  messageText: string;
  sentAt: firebase.firestore.Timestamp;
  hide?: boolean;
  attachments?: Attachment[];
  senderName?: string;
  chatId?: string;
  messageType?: MessageType.Text;
}

export interface DateTimeObject {
  seconds: number;
  nanoseconds: number;
}

export interface GroupRawChat {
  id: string;
  name?: string;
  picture?: string;
  createdAt: firebase.firestore.Timestamp;
  createdBy: number;
  modifiedAt: firebase.firestore.Timestamp;
  members: number[];
  hide?: boolean;
  lastMessage?: RawMessage;
  activeCall: ActiveCall | null;
  pendingCallType?: CallType;
  type: FirestoreChatType.Group;
}

export interface ActiveCall {
  chatId: string;
  roomSid: string;
  participants?: ActiveCallParticipant[];
  senderId: number;
}

export interface ActiveCallParticipant {
  id: number;
  iosCallUuid: string | undefined;
  status: ActiveCallParticipantStatus;
}

export enum ActiveCallParticipantStatus {
  connected = "connected",
  declined = "declined",
  disconnected = "disconnected",
}

export interface RecentMessage {
  id: string;
  read?: boolean;
  readAt?: firebase.firestore.Timestamp;
  senderId: number;
  messageText: string;
  sentAt: firebase.firestore.Timestamp;
  hide?: boolean;
  attachment: string[];
  delivered: true;
  senderName: string;
}

export type GroupRawTextMessage = {
  id: string;
  senderId: number;
  messageText: string;
  sentAt: firebase.firestore.Timestamp;
  hide: boolean;
  readBy: number[];
  attachments?: Attachment[];
  senderName?: string;
  chatId: string;
  messageType?: MessageType.Text;
};

export interface RawFriendRequestMessage {
  id: string;
  sentAt: any;
  senderId: number;
  receiverId: number;
  messageType: MessageType.FriendRequest;
  status: FriendRequestStatus;
  hide?: boolean;
  chatId: string;
}

export enum FriendRequestStatus {
  Pending = "pending",
  Accepted = "accepted",
  Ignored = "ignored",
  Cancelled = "cancelled",
}

export interface OneToOneRawAudioMessage {
  id: string;
  sentAt: any;
  senderId: number;
  readAt?: any;
  read: boolean;
  hide?: boolean;
  attachments?: Attachment[];
  messageType: MessageType.Audio;
  chatId?: string;
}

export interface GroupRawAudioMessage {
  id: string;
  sentAt: any;
  senderId: number;
  readBy: number[];
  hide?: boolean;
  attachments?: Attachment[];
  messageType: MessageType.Audio;
  chatId?: string;
}

export type RawAudioMessage = OneToOneRawAudioMessage | GroupRawAudioMessage;

export interface RawCallMessage {
  id: string;
  roomSid?: string;
  senderId: number;
  messageType: MessageType.Call;
  status: CallMessageStatus;
  duration: number | null;
  sentAt: firebase.firestore.Timestamp;
  hide: boolean;
  chatId?: string;
}

export enum CallMessageStatus {
  Completed = "completed",
  Cancelled = "cancelled",
}

export enum MessageType {
  Text = "text",
  Call = "call",
  Audio = "audio",
  FriendRequest = "friendRequest",
}

export interface Chat {
  type: ChatTypes;
  id: string;
  name: string;
  hasName: boolean;
  nameFromMembers: string;
  picture: string;
  createdAt: number;
  createdBy?: number;
  modifiedAt: number;
  membersIds: number[];
  members?: User[];
  hidden: boolean;
  partner?: User;
  isOwner?: boolean;
  latestMessage?: Message;
  blockingStatus?: ChatBlockingStatusEnum;
  isDeleted?: boolean;
  activeCall: ActiveCall | null;
  pendingCallType: CallType;
}

export interface TextMessage {
  chatType: ChatType;
  id: string;
  sentAt: number;
  hidden: boolean;
  senderId: number;
  senderName?: string;
  readBy?: ReadBy[];
  isMine: boolean;
  messageText: string;
  displayMessageText: string;
  messageTextWithSenderName?: string;
  parseMessageText: string;
  hasMessageText: boolean;
  attachment?: string[];
  hasAttachment: boolean;
  read: boolean;
  statusIcon?: IconType;
  attachments?: Attachment[];
  allAttachments?: Attachment[];
  imageAttachments?: Attachment[];
  videoAttachments?: Attachment[];
  othersAttachments?: Attachment[];
  // undefined to support legacy messages with no type
  type?: MessageType.Text;
}

export interface AudioMessage {
  chatType: ChatType;
  id: string;
  sentAt: number;
  hidden: boolean;
  senderId: number;
  senderName?: string;
  readBy?: ReadBy[];
  isMine: boolean;
  attachment?: string[];
  read: boolean;
  statusIcon?: IconType;
  attachments?: Attachment[];
  type: MessageType.Audio;
}

export interface CallMessage {
  chatType: ChatType;
  id: string;
  sentAt: number;
  hidden: boolean;
  senderId: number;
  type: MessageType;
  status: CallMessageStatus;
  duration: string | null;
}

export interface FriendRequestMessage {
  chatType: ChatType;
  id: string;
  sentAt: number;
  hidden: boolean;
  senderId: number;
  senderName?: string;
  isMine: boolean;
  type: MessageType.FriendRequest;
  status: FriendRequestStatus;
}

export type Message =
  | TextMessage
  | CallMessage
  | AudioMessage
  | FriendRequestMessage;

export interface AnalyzeNewUserChatIds {
  sameIds: string[];
  newIds: string[];
  removedIds: string[];
}

export interface AnalyzeMessageId {
  chatId: string;
  senderId: string;
  hash: string;
  messageId: string;
}

export enum ChatTypes {
  ONE_TO_ONE_CHAT = "ONE_TO_ONE_CHAT",
  GROUP_CHAT = "GROUP_CHAT",
  NEW_CHAT_TYPE = "NEW_CHAT_TYPE",
}

export type ChatType = ChatTypes.ONE_TO_ONE_CHAT | ChatTypes.GROUP_CHAT;

export enum FirestoreChatType {
  OneToOne = "oneToOne",
  Group = "group",
}

export interface MessageState {
  messageId: string;
  message: TextMessage;
  rawMessage?: RawTextMessage;
}

export type MessagesState = IdPrefixedResultObject<MessageState>;
export type RawTextMessage = OneToOneRawTextMessage | GroupRawTextMessage;
export type RawMessagesState = IdPrefixedResultObject<RawMessage>;
export type RawMessage =
  | RawTextMessage
  | RawCallMessage
  | RawAudioMessage
  | RawFriendRequestMessage;

export type RawChat = OneToOneRawChat | GroupRawChat;

export interface ChatState {
  chatUnsubscribe?: () => void;
  messagesUnsubscribe?: () => void;
  rawChat?: RawChat;
  messages: RawMessagesState;
  messageIds: string[];
  lastMessageSentAt?: firebase.firestore.Timestamp;
  chatId: string;
  convertedChat?: Chat;
}

export interface ChatsStates {
  [chatId: string]: ChatState;
}

export enum ChatBlockingStatusEnum {
  None = "none",
  Me = "me",
  Them = "them",
}

export enum FirestoreDocumentChangeType {
  Added = "added",
  Removed = "removed",
  Modified = "modified",
}

export interface DeleteAttachmentArgs {
  chatId: string;
  messageId: string;
  attachmentIdx: number;
}

export interface CreateCachedMessageArgs {
  messageText: string;
  attachments: Attachment[];
  chatId: string;
  messageType: MessageType;
}

export interface CreateCachedTextMessageArgs {
  messageText: string;
  attachments: Attachment[];
  senderId: number;
  chatId: string;
}

export interface CreateCachedAudioMessageArgs {
  attachments: Attachment[];
  senderId: number;
  chatId: string;
}

export interface UpdateChatMembersArgs {
  chatId: string;
  firebaseIdToken: string;
  addMemberIds?: string[];
  removeMemberIds?: string[];
}
