import store from "@/store";
import { getPartnerIdFromChatId } from "@/firebase/utils";
import { RawChat, GroupRawChat } from "@/store/chats/types";
import { convertRawMessage } from "../message";
import { convertRawGroupChat } from "./convertRawGroupChat";
import { convertRawOneToOneChat } from "./convertRawOneToOneChat";
import { User } from "@/store/users/state";
import { getIsOneToOneRawChat } from "../message/getIsOneToOneRawChat";
import { findUserById } from "../users";

export const convertRawChat = (rawChat: RawChat, users?: User[]) => {
  const chatId = rawChat.id;
  const authProfile = store.state.auth.profile;
  const authId = store.state.auth.profile?.id;
  const lastMessage = convertRawMessage({
    rawMessage: rawChat.lastMessage,
    rawChat,
    users,
  });

  if (getIsOneToOneRawChat(rawChat)) {
    const partnerId = getPartnerIdFromChatId(authId, chatId);
    const partner = findUserById(partnerId, users);
    if (!partner) {
      throw new Error(`could not find user with id ${partnerId}`);
    }

    return convertRawOneToOneChat(rawChat, authProfile, partner, lastMessage);
  }
  const groupChat = rawChat as GroupRawChat;
  const memberIds = groupChat.members;
  const members = memberIds
    .map((memberId) => {
      return findUserById(memberId, users);
    })
    .filter(Boolean) as User[];

  return convertRawGroupChat(groupChat, authProfile, members, lastMessage);
};
