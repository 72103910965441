export interface IWhatIsNewPoint {
  key: string;
}

export interface IWhatIsNewObject {
  [key: string]: IWhatIsNewPoint[];
}

export interface InfiniteScrollData {
  isLoadingMore: boolean;
  canLoadMore: boolean;
  listEndObserver: IntersectionObserver | null;
  previousScrollHeightMinusScrollTop: number;
}

export enum InfiniteScrollDirection {
  Up = "up",
  Down = "down",
}

export enum MobileNavigationBarIconType {
  Circle = "circle",
  Default = "default",
}

export interface MobileNavigationProps {
  hideDivider?: boolean;
  title?: string;
  disableTitleTranslation?: boolean;
  handleRedirect?: () => void;
  handleAction?: (() => void) | (() => Promise<void>);
  actionTitle?: string;
  iconType?: MobileNavigationBarIconType;
}
