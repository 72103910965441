import {
  OneToOneRawAudioMessage,
  RawAudioMessage,
  RawTextMessage,
} from "@/store/chats/types";
import { isOneToOneChat } from "@/firebase/utils";

export const getIsOneToOneRawAudioMessage = (
  _: RawAudioMessage,
  chatId: string,
): _ is OneToOneRawAudioMessage => {
  return isOneToOneChat(chatId);
};
