import {
  convertFirebaseDateToLocal,
  getPartnerIdFromChatId,
} from "@/firebase/utils";
import { Profile } from "@/store/auth/state";
import {
  OneToOneRawTextMessage,
  ChatTypes,
  MessageType,
  TextMessage,
} from "@/store/chats/types";
import { User } from "@/store/users/state";
import { parseMessageText } from ".";
import { getDisplayOneToOneMessage } from "../chat/getDisplayOneToOneMessage";
import { getImageAttachments } from "../chat/getImageAttachments";
import { getOneToOneMessageReadBy } from "../chat/getOneToOneMessageReadBy";
import { getOthersAttachments } from "../chat/getOthersAttachments";
import { getReadOneToOneMessage } from "../chat/getReadOneToOneMessage";
import { getSenderName } from "../chat/getSenderName";
import { getStatusIcon } from "../chat/getStatusIcon";
import { getVideoAttachments } from "../chat/getVideoAttachments";
import { getVisibleAttachments } from "./getVisibleAttachments";
import { findUserById } from "../users";

interface ConvertRawOneToOneTextMessageArgs {
  rawMessage: OneToOneRawTextMessage;
  chatId: string;
  auth: Profile;
  users?: User[];
}

export const convertRawOneToOneTextMessage = ({
  rawMessage,
  chatId,
  auth,
  users,
}: ConvertRawOneToOneTextMessageArgs): TextMessage => {
  const { senderId, messageText, attachments, hide, sentAt, id } = rawMessage;
  const visibleAttachments = getVisibleAttachments(attachments);
  const partnerId = getPartnerIdFromChatId(auth.id, chatId);
  const partner = findUserById(partnerId, users);
  const members = [partner].filter(Boolean) as User[];
  const hasAttachment = Boolean(attachments && attachments.length);
  const hasMessageText = !!messageText;
  const hidden = Boolean(hide) && !(hasMessageText || hasAttachment);

  return {
    chatType: ChatTypes.ONE_TO_ONE_CHAT,
    id,
    senderId,
    senderName: getSenderName(senderId, auth.id, members),
    isMine: auth.id === senderId,
    messageText,
    displayMessageText: getDisplayOneToOneMessage(rawMessage),
    parseMessageText: parseMessageText(messageText),
    sentAt: convertFirebaseDateToLocal(sentAt),
    hasAttachment,
    hasMessageText,
    hidden,
    readBy: getOneToOneMessageReadBy(rawMessage, auth, chatId),
    read: getReadOneToOneMessage(rawMessage, auth),
    statusIcon: getStatusIcon(rawMessage, auth),
    attachments: visibleAttachments,
    allAttachments: attachments,
    imageAttachments: getImageAttachments(visibleAttachments),
    videoAttachments: getVideoAttachments(visibleAttachments),
    othersAttachments: getOthersAttachments(visibleAttachments),
    type: MessageType.Text,
  };
};
