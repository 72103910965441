<template>
  <ChatMessageContent
    :index="index"
    :message="message"
    :visible-messages="visibleMessages"
  >
    <ChatMessageSender v-bind="props" />
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-if="message?.messageText"
      class="message-text"
      :class="containsOnlyEmojis(message.messageText) ? 'large' : ''"
      v-html="message.parseMessageText"
    />
    <!-- eslint-enable -->
    <div
      v-if="message?.othersAttachments?.length"
      class="attachments-others"
      :class="message?.messageText ? 'padding-top' : ''"
    >
      <OtherAttachment
        v-for="attachment in message.othersAttachments"
        :key="attachment.id"
        :attachment="attachment"
        :message="message"
      />
    </div>
    <ImageAttachment
      v-if="message?.imageAttachments?.length === 1"
      :attachment="message.imageAttachments[0]"
      :message="message"
    />
    <template #imageAttachment>
      <div
        v-if="getIsAttachmentsImageDisplayed(message)"
        class="attachments-image"
      >
        <ImageAttachment
          v-for="attachment in message?.imageAttachments"
          :key="attachment.id"
          :attachment="attachment"
          :gallery-mode="true"
          :message="message"
        />
      </div>
    </template>
    <template #videoPlayer>
      <VideoPlayer
        v-for="attachment in message?.videoAttachments"
        :key="attachment.id"
        :attachment="attachment"
        :message="message"
        :class="getMessageClass(visibleMessages, index, authId)"
      />
    </template>
    <template #checkboxSelectMessage>
      <CheckboxSelectMessage :message="message" />
    </template>
  </ChatMessageContent>
</template>

<script setup lang="ts">
import CheckboxSelectMessage from "@/components/checkbox/CheckboxSelectMessage.vue";
import ImageAttachment from "@/components/chat/chatContainer/ImageAttachment/ImageAttachment.vue";
import ChatMessageContent from "@/components/chat/chatContainer/ChatMessageContent/ChatMessageContent.vue";
import VideoPlayer from "@/components/chat/chatContainer/VideoPlayer/VideoPlayer.vue";
import OtherAttachment from "@/components/chat/chatContainer/OtherAttachment/OtherAttachment.vue";
import ChatMessageSender from "@/components/chat/chatContainer/ChatMessageSender/ChatMessageSender.vue";
import { getMessageClass } from "@/utils/message";
import { getIsAttachmentsImageDisplayed } from "@/utils/chat";
import { containsOnlyEmojis } from "@/utils/emoji";
import { Message, TextMessage } from "@/store/chats/types";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { computed } from "vue";
import { getPhoneContactUserAliasFromId } from "@/utils/users";

interface ChatMessageProps {
  message: TextMessage;
  index: number;
  visibleMessages: Message[];
}

const props = defineProps<ChatMessageProps>();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
</script>

<style lang="scss" scoped>
@import "./ChatTextMessage.scss";
</style>
