import { config } from "@/config";
import { chatApiAxios } from "@/plugins";
import { HttpRequestMethodEnum } from "@/types/api";
import { ISentMessage } from "@/types/chat";

export const sendMessageBeAsync = async ({
  chatId,
  messageText,
  messageId,
  firebaseIdToken,
  attachments,
  messageType,
}: ISentMessage) => {
  const headers = {
    Authorization: `Bearer ${firebaseIdToken}`,
    "Content-Type": "application/json",
  };

  const res = await chatApiAxios(config.api.chatApi.endpoints.message.index, {
    method: HttpRequestMethodEnum.POST,
    headers,
    data: {
      chatId,
      messageId,
      messageText,
      attachments,
      messageType,
    },
  });

  return res.data;
};
