<template>
  <ChatMessageContent
    :index="index"
    :message="message"
    :visible-messages="visibleMessages"
  >
    <div class="call-message">
      <div class="left">
        <Icon :icon="statusProperties.arrowIcon" class="arrow" />
        <span
          class="message-text"
          :class="[{ incoming: !isOutgoing }, message.status]"
          >{{ text }}
        </span>
      </div>
    </div>
  </ChatMessageContent>
</template>

<script setup lang="ts">
import ChatMessageContent from "@/components/chat/chatContainer/ChatMessageContent/ChatMessageContent.vue";
import { CallMessage, Message } from "@/store/chats/types";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useComputedValue } from "@/composables";
import { GetterTypes } from "@/store";
import { getCallStatusProperties } from "@/utils/message";
import Icon from "@/components/icons/Icon/Icon.vue";

interface ChatMessageProps {
  message: CallMessage;
  index: number;
  visibleMessages: Message[];
}

const props = defineProps<ChatMessageProps>();
const { t } = useI18n();
const authId = useComputedValue<number>(GetterTypes.GET_AUTH_ID);
const isOutgoing = computed(() => authId.value === props.message.senderId);
const statusProperties = computed(() =>
  getCallStatusProperties({
    isOutgoing: isOutgoing.value,
    status: props.message.status,
    duration: props.message.duration,
  }),
);
const text = computed(
  () => t(statusProperties.value.text) + statusProperties.value.duration,
);
</script>

<style lang="scss" scoped>
@import "./ChatCallMessage.scss";
</style>
