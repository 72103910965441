import store from "@/store";
import { Profile } from "@/store/auth/state";
import { RawChat, RawMessage } from "@/store/chats/types";
import { convertRawCallMessage } from "./convertRawCallMessage";
import { convertRawTextMessage } from "./convertRawTextMessage";
import { getIsRawMessageTextType } from "./getIsRawMessageTextType";
import { User } from "@/store/users/state";
import { getIsRawMessageAudioType } from "./getIsRawMessageAudioType";
import { convertRawAudioMessage } from "./convertRawAudioMessage";
import { getIsRawMessageFriendRequestType } from "./getIsRawMessageFriendRequestType";
import { convertRawFriendRequestMessage } from "./convertRawFriendRequestMessage";

interface ConvertRawMessageArgs {
  rawMessage?: RawMessage;
  rawChat: RawChat;
  users?: User[];
}

export const convertRawMessage = ({
  rawMessage,
  rawChat,
  users,
}: ConvertRawMessageArgs) => {
  if (!rawMessage) {
    return;
  }
  const authProfile: Profile = store.getters.GET_AUTH_PROFILE;
  const chatId = rawChat.id;
  const commonArgs = {
    rawChat,
    auth: authProfile,
    users,
  };
  if (getIsRawMessageTextType(rawMessage)) {
    return convertRawTextMessage({ ...commonArgs, rawMessage });
  }
  if (getIsRawMessageAudioType(rawMessage)) {
    return convertRawAudioMessage({ ...commonArgs, rawMessage });
  }

  if (getIsRawMessageFriendRequestType(rawMessage)) {
    return convertRawFriendRequestMessage({ ...commonArgs, rawMessage });
  }

  return convertRawCallMessage(rawMessage, chatId);
};
