<template>
  <div ref="el" class="chat-content" @click="handleBlurInput">
    <div ref="scrollContainerRef" class="messages-wrapper">
      <div ref="sentinelRef" />
      <ChatMessage
        v-for="(message, index) in visibleMessages"
        :key="message.id"
        :index="index"
        :message="message"
        :visible-messages="visibleMessages"
      />
      <span
        v-if="showReadAt(visibleMessages, visibleMessages.length - 1)"
        class="read-at"
        :class="deletingMessages ? 'padding-right' : ''"
      >
        {{ $t("chat.chatContainer.read") }}
        {{ convertedDateString }}
      </span>
      <div v-if="isBlockingTextShown" class="blocking-text">
        <p v-for="(str, i) in blockingStrings" :key="i">
          {{ str }}
        </p>
      </div>
      <div ref="bottomAnchorRef" />
    </div>
  </div>
</template>

<script setup lang="ts">
import ChatMessage from "@/components/chat/chatContainer/ChatMessage/ChatMessage.vue";
import { showReadAt } from "@/utils/message";
import { useGetChatContentProperties } from "./useGetChatContentProperties";
import { ChatBlockingStatusEnum, Message } from "@/store/chats/types";

interface ChatContentProps {
  messages: Message[];
  blockingStatus?: ChatBlockingStatusEnum;
  isDeleted?: boolean;
  userName?: string;
}

const props = withDefaults(defineProps<ChatContentProps>(), {
  messages: () => [],
  blockingStatus: undefined,
  isDeleted: undefined,
  userName: "",
});

const {
  el,
  scrollContainerRef,
  handleBlurInput,
  visibleMessages,
  isBlockingTextShown,
  deletingMessages,
  convertedDateString,
  blockingStrings,
  sentinelRef,
  bottomAnchorRef,
} = useGetChatContentProperties(props);
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatContent.scss";
</style>
