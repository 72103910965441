<template>
  <div
    ref="root"
    class="chat-wrapper"
    :class="{ isBlocking: isBlockingTextShown }"
    :style="style"
  >
    <ChatTopBar />
    <ChatContent :messages="messages" />
    <ChatInputBar v-if="!isBlockingTextShown" :members="members" />
  </div>
</template>

<script setup lang="ts">
import ChatTopBar from "@/components/chat/chatContainer/ChatTopBar/ChatTopBar.vue";
import ChatInputBar from "@/components/chat/chatContainer/ChatInputBar/ChatInputBar.vue";
import ChatContent from "@/components/chat/chatContainer/ChatContent/ChatContent.vue";
import { useGetChatContentContainerProperties } from "./useGetChatContentContainerProperties";

const { root, style, isBlockingTextShown, messages, members } =
  useGetChatContentContainerProperties();
</script>

<style lang="scss" scoped>
@import "src/assets/scss/chat/chatContainer/chatContainer.scss";
</style>
