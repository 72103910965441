<template>
  <div
    ref="root"
    class="container"
    :class="backgroundColorTypeTop.toLowerCase()"
    :style="style"
  >
    <div class="content"><slot /></div>
  </div>
</template>
<script setup lang="ts">
import { useComputedValue } from "@/composables/useComputedValue";
import { useVisualViewport } from "@/composables/useVisualViewport";
import { GetterTypes } from "@/store";
import { LayoutBackgroundColorTypes } from "@/store/app/state";
import { ref } from "vue";

const root = ref<HTMLElement>();
const { style } = useVisualViewport(root);

const backgroundColorTypeTop = useComputedValue<LayoutBackgroundColorTypes>(
  GetterTypes.GET_LAYOUT_BG_COLOR_TOP,
);
</script>
<style lang="scss" scoped>
@import "src/assets/scss/layout/mobileLayout";
</style>
