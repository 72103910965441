<template>
  <div
    class="attachment-image"
    :class="`${getIsMyMessageClass(message)} ${galleryMode ? 'gallery' : ''}`"
    @click="openImage(attachment.url)"
  >
    <img loading="lazy" :style="appliedStyle" :src="attachment.url" />
    <Loader
      v-if="attachment?.uploadProgress !== undefined && !attachment?.uploaded"
      :progress="attachment.uploadProgress"
      :overlay="true"
    />
  </div>
</template>

<script>
import Loader from "@/components/chat/chatContainer/Loader.vue";
import _ from "lodash";
import { GetterTypes, MutationTypes, store } from "@/store";
import { LayoutBackgroundColorTypes } from "@/store/app/state";
import { mapGetters } from "vuex";
import { getIsMyMessageClass } from "@/utils/chat";

export default {
  components: {
    Loader,
  },
  props: {
    attachment: {
      required: true,
      type: Object,
    },
    galleryMode: {
      type: Boolean,
      default: false,
      required: false,
    },
    message: {
      type: Object,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      temporaryColorBottom: null,
      temporaryColorTop: null,
      appliedStyle: undefined,
    };
  },
  computed: {
    ...mapGetters({
      src: GetterTypes.GET_FULL_SIZE_IMAGE,
      colorBottom: GetterTypes.GET_LAYOUT_BG_COLOR_BOTTOM,
      colorTop: GetterTypes.GET_LAYOUT_BG_COLOR_TOP,
    }),
  },

  watch: {
    src: _.throttle(
      function () {
        this.updateImageSrc();
      },
      500,
      {
        leading: true,
        trailing: false,
      },
    ),
  },
  mounted() {
    this.getAppliedStyle();
  },
  methods: {
    openImage(src) {
      this.temporaryColorBottom = this.colorBottom;
      this.temporaryColorTop = this.colorTop;
      store.commit(MutationTypes.SET_FULL_SIZE_IMAGE, src);
    },
    getImageNaturalSize(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();

        // the following handler will fire after a successful loading of the image
        img.onload = () => {
          const { naturalWidth: width, naturalHeight: height } = img;
          resolve({ width, height });
        };

        // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
        img.onerror = () => {
          reject("There was some problem with the image.");
        };

        img.src = url;
      });
    },
    async getImageDimensions() {
      const { width, height } = await this.getImageNaturalSize(
        this.attachment?.url,
      );
      const imageSize = this.getImageSize(width, height);
      return imageSize;
    },
    getImageSize(w, h) {
      let width = w || 0;
      let height = h || 0;
      let ratio;

      if (this.galleryMode) return;

      const MAX_DIMENSIONS = 350;

      if (width > height) {
        ratio = height / width;
        if (width > MAX_DIMENSIONS) {
          width = MAX_DIMENSIONS;
        }
        height = width * ratio;
      } else {
        ratio = width / height;
        if (height > MAX_DIMENSIONS) {
          height = MAX_DIMENSIONS;
        }
        width = height * ratio;
      }

      return {
        width,
        height,
      };
    },
    async getAppliedStyle() {
      if (this.attachment?.width && this.attachment?.height) {
        const attSizes = this.getImageSize(
          this.attachment?.width,
          this.attachment?.height,
        );
        this.appliedStyle = {
          width: `${attSizes?.width || ""}px`,
          height: `${attSizes?.height || ""}px`,
        };
        return;
      }
      const imageSize = await this.imageSize();

      this.appliedStyle = {
        width: `${imageSize?.width || ""}px`,
        height: `${imageSize?.height || ""}px`,
      };
    },
    imageSize() {
      return this.getImageDimensions();
    },
    updateImageSrc() {
      let resultColorBottom = this.temporaryColorBottom;
      let resultColorTop = this.temporaryColorTop;
      if (this.src) {
        resultColorBottom = LayoutBackgroundColorTypes.BLACK;
        resultColorTop = LayoutBackgroundColorTypes.BLACK;
      }

      store.commit(
        MutationTypes.SET_LAYOUT_BACKGROUND_COLOR_TOP,
        resultColorTop,
      );
      store.commit(
        MutationTypes.SET_LAYOUT_BACKGROUND_COLOR_BOTTOM,
        resultColorBottom,
      );
    },
    getIsMyMessageClass,
  },
};
</script>
<style lang="scss" scoped>
@import "./ImageAttachment.scss";
</style>
