import store from "@/store";
import { Profile } from "@/store/auth/state";
import {
  CreateCachedMessageArgs,
  GroupRawAudioMessage,
  GroupRawTextMessage,
  MessageType,
} from "@/store/chats/types";
import { createCachedGroupTextMessage } from "./createCachedGroupTextMessage";
import { createCachedGroupAudioMessage } from "./createCachedGroupAudioMessage";

export const createCachedGroupChatMessage = ({
  messageText,
  attachments,
  chatId,
  messageType,
}: CreateCachedMessageArgs): GroupRawTextMessage | GroupRawAudioMessage => {
  const auth = store.getters.GET_AUTH_PROFILE as Profile;
  const commonArgs = { attachments, senderId: auth.id, chatId };

  if (messageType === MessageType.Text) {
    return createCachedGroupTextMessage({
      ...commonArgs,
      messageText,
    });
  }

  return createCachedGroupAudioMessage(commonArgs);
};
