<template>
  <a
    v-if="isShown && isAppEnvironmentIos"
    :href="getLink()"
    target="_blank"
    rel="noopener noreferer"
    class="banner"
  >
    <img class="img" :src="bannerSrc" alt="mobile_installation_banner" />
  </a>
</template>

<script setup lang="ts">
import { GetterTypes } from "@/store";
import { useComputedValue } from "@/composables";
import {
  isAppEnvironmentIos,
  isStandaloneAppEnvironmentContainer,
} from "@/utils/app";
import { getLanguage } from "@/utils/api";
import { LayoutTypes } from "@/store/app/state";
import { computed } from "vue";

const initialLoad = useComputedValue(GetterTypes.GET_CHATS_INITIAL_LOAD);
const layoutType = useComputedValue<LayoutTypes>(GetterTypes.GET_LAYOUT_TYPE);
const language = getLanguage();

const googlePlayStore = process.env.VUE_APP_ANDROID_INSTALL_LINK;
const appleStore = process.env.VUE_APP_IOS_INSTALL_LINK;

const getLink = () => {
  return isAppEnvironmentIos ? appleStore : googlePlayStore;
};

const os = isAppEnvironmentIos
  ? "/banners/banner-appstore-"
  : "/banners/banner-playstore-";
const bannerSrc = `${os}${language}.jpg`;
const isShown = computed(
  () =>
    initialLoad &&
    !isStandaloneAppEnvironmentContainer &&
    layoutType.value === LayoutTypes.MOBILE,
);
</script>

<style lang="scss">
@import "./MobileInstallationBanner.module.scss";
</style>
