import { ChatsStates, ChatState, ChatTypes } from "./types";

export interface ChatsState {
  authChatsUnsubscribe: (() => void) | null;
  initialLoadCompleted: boolean;
  chatsFilter: string;
  selectedChat?: string | ChatTypes.NEW_CHAT_TYPE | null;
  selectedMessageIds?: string[];
  futureSelectedChatId?: string;
  // oneToOneChatIds: string[];
  // groupChatIds: string[];
  chatIds: string[];
  allChatIds: string[];
  remainingChatIds: string[];
  // allOneToOneChatIds: string[];
  // allGroupChatIds: string[];
  // remainingOneToOneChatIds: string[];
  // remainingGroupChatIds: string[];
  // oneToOneChatsUnsubscribe: (() => void) | null;
  // groupChatsUnsubscribe: (() => void) | null;
  chats: ChatsStates;
  sharedMessage?: string;
  sharedImages?: string[];
}

export const chatsState: ChatsState = {
  authChatsUnsubscribe: null,
  // oneToOneChatsUnsubscribe: null,
  // groupChatsUnsubscribe: null,
  initialLoadCompleted: false,
  chatsFilter: "",
  // oneToOneChatIds: [],
  // groupChatIds: [],
  // allOneToOneChatIds: [],
  // allGroupChatIds: [],
  // remainingOneToOneChatIds: [],
  // remainingGroupChatIds: [],
  chatIds: [],
  allChatIds: [],
  remainingChatIds: [],
  chats: {},
  sharedMessage: "",
  sharedImages: [],
};

export type ChatGetterHandler = (chatId: string) => ChatState | null;
