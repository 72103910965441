export enum ChatsMutationTypes {
  REMOVE_CHAT = "REMOVE_CHAT",
  SET_AUTH_CHATS_UNSUBSCRIBE = "SET_AUTH_CHATS_UNSUBSCRIBE",
  SET_CHAT_UNSUBSCRIBE = "SET_CHAT_UNSUBSCRIBE",
  SET_MESSAGES_UNSUBSCRIBE = "SET_MESSAGES_UNSUBSCRIBE",
  SET_CHAT_DATA = "SET_CHAT_DATA",
  SET_CHATS_FILTER = "SET_CHATS_FILTER",
  CLEAR_CHATS_FILTER = "CLEAR_CHATS_FILTER",
  SET_MESSAGE_DATA = "SET_MESSAGE_DATA",
  SET_SELECTED_CHAT = "SET_SELECTED_CHAT",
  REMOVE_SELECTED_CHAT = "REMOVE_SELECTED_CHAT",
  SET_FUTURE_SELECTED_CHAT_ID = "SET_FUTURE_SELECTED_CHAT_ID",
  REMOVE_FUTURE_SELECTED_CHAT_ID = "REMOVE_FUTURE_SELECTED_CHAT_ID",
  TOGGLE_SELECTED_CHAT_NEW_CHAT = "TOGGLE_SELECTED_CHAT_NEW_CHAT",
  SET_SELECTING_MESSAGES = "SET_SELECTING_MESSAGES",
  TOGGLE_SELECTED_MESSAGE_ID = "TOGGLE_SELECTED_MESSAGE_ID",
  SET_UPLOADING_MESSAGE = "SET_UPLOADING_MESSAGE",
  REMOVE_UPLOADING_MESSAGE = "REMOVE_UPLOADING_MESSAGE",
  UPDATE_UPLOADING_MESSAGE_PROGRESS = "UPDATE_UPLOADING_MESSAGE_PROGRESS",
  UPDATE_UPLOADING_MESSAGE_ATTACHMENT_CDN_ID = "UPDATE_UPLOADING_MESSAGE_ATTACHMENT_CDN_ID",
  SET_SELECTED_CHAT_BLOCKING_STATUS = "SET_SELECTED_CHAT_BLOCKING_STATUS",
  SET_CHATS = "SET_CHATS",
  PREPEND_MESSAGES = "PREPEND_MESSAGES",
  APPEND_CHATS = "APPEND_CHATS",
  UPDATE_MESSAGE_DISPLAY_STATUS = "UPDATE_MESSAGE_DISPLAY_STATUS",
  UPDATE_ATTACHMENT_IS_UPLOADING = "UPDATE_ATTACHMENT_IS_UPLOADING",
  SET_ALL_CHAT_IDS = "SET_ALL_CHAT_IDS",
  SET_SHARED_MESSAGE = "SET_SHARED_MESSAGE",
}
